import React from "react";

export const winners = [
  {
    groupTitle: "First Place",
    teams: [
      {
        teamName: "Gurami",
        summary: "Gurami is an Indonesian-based company that aims to close the loop of the current Indonesian food system by upcycling food waste as one of the highest greenhouse gas contributors. We generate value in the Circular Economy by developing the potential of the Black Soldier Fly by bio-converting food waste into protein-rich fish feed. We aim to prosper the welfare of fish farmers by providing high-quality, safe, affordable, and environmentally friendly products by producing high-quality fish feed locally and empowering rural communities in Indonesia.",
        members: [
          {
            name: "Moch Graha",
            school: "University of Pennsylvania",
          },
        ],
      },
    ],
  },
  {
    groupTitle: "Second Place",
    teams: [
      {
        teamName: "Rethink Symbio",
        summary: "Currently nitrogen fertilizer alone contributes to 2.4% of global emissions, and excess application of N-fertilizer causes nitrous oxide emissions equivalent to 6.8M tons of CO2 annually. Rethink Symbio delivers a microbe-based fertilizer designed to prevent excess fertilizer application and carbon intensive synthetic fertilizer manufacturing processes. We study high yielding healthy plants and identify strains that are beneficial to the plant and resistant to being outcompeted by others in soil, create base formulations for broad crop categories (ie legumes, citrus, etc.), and then deliver custom formulations accounting for the specific crop type, soil sample that farmers send us, and local seasonal weather patterns.",
        members: [
          {
            name: "Yi-An Hsieh",
            school: "University of Pennsylvania",
          },
          {
            name: "Parth Mody",
            school: "University of Pennsylvania",
          },
        ],
      },
    ]
  },
  {
    groupTitle: "Third Place",
    teams: [
      {
        teamName: "Aloe Points",
        summary: "Aloe Points is the social app that empowers users to be their most sustainable selves. Aloe Points hosts personalized and actionable sustainability missions, embedded with social features and sustainable rewards. Missions consist of a mix of native challenges and customized business-partner challenges to promote local engagement (e.g. zero-waste stores, plant-based cafes). Learn about the impact of your choices, reduce your carbon footprint, engage your friends, and unlock sustainable rewards! Being sustainable has never been this motivating and engaging!",
        members: [
          {
            name: "Adarsh Kumar",
            school: "University of Chicago",
          },
          {
            name: "Saloni Mundhra",
            school: "University of Chicago",
          },
        ],
      },
    ],
  },
  {
    groupTitle: "Honorable Mentions",
    teams: [
      {
        teamName: "Pollinator, Waxworm Generator!",
        summary: "We are seeking new ways to solve long-standing problems through harnessing the power of bugs to make the world a better place. This project is even more important nowadays as insect populations have declined due to habitat loss and the widespread use of pesticides. Our goal is to preserve the amazing biological diversity that nature has provided by finding ways that unique abilities among Earth's organisms can help us break down plastic and food waste and convert them into useful products that will further reduce our ecological impact and promote continued sustainability.",
        members: [
          {
            name: "Sharon Eastburn",
            school: "Pennsbury High School",
          },
          {
            name: "George Kopf",
            school: "Princeton High School",
          },
          {
            name: "Julie Liu",
            school: "Princeton High School",
          },
          {
            name: "Katherine Monroe",
            school: "Princeton High School",
          },
        ],
      },
      {
        teamName: "Hydrovia",
        members: [
          {
            name: "Chase Leffers",
            school: "Northeastern University",
          },
        ],
      },
    ],
  },
]

export const finalists = [
  {
    track: "Energy and Transportation",
    teams: [
      {
        teamName: "Dehdoh Travel",
        members: [
          {
            name: "Ishaaq Shafi",
            school: "University of Pennsylvania",
          },
        ]
      },
      {
        teamName: "Powerhouse",
        members: [
          {
            name: "Blessed Kutyauripo",
            school: "Prince Edward School",
          },
          {
            name: "Atupele Phiri",
            school: "Prince Edward School",
          },
        ]
      },
    ]
  },
  {
    track: "Food and Agriculture",
    teams: [
      {
        teamName: "Rethink Symbio",
        members: [
          {
            name: "Yi-An Hsieh",
            school: "University of Pennsylvania",
          },
          {
            name: "Parth Mody",
            school: "University of Pennsylvania",
          },
        ],
      },
      {
        teamName: "Hydrovia",
        members: [
          {
            name: "Chase Leffers",
            school: "Northeastern University",
          },
        ],
      },,
    ]
  },
  {
    track: "Waste and Circularity",
    teams: [
      {
        teamName: "Gurami",
        members: [
          {
            name: "Moch Graha",
            school: "University of Pennsylvania",
          },
        ],
      },
      {
        teamName: "e-Salvage",
        members: [
          {
            name: "Matthias Choi",
            school: "Northern Valley Regional High School",
          },
        ]
      },
    ]
  },
  {
    track: "Wild Card",
    teams: [
      {
        teamName: "Pollinator, Waxworm Generator!",
        members: [
          {
            name: "Sharon Eastburn",
            school: "Pennsbury High School",
          },
          {
            name: "George Kopf",
            school: "Princeton High School",
          },
          {
            name: "Julie Liu",
            school: "Princeton High School",
          },
          {
            name: "Katherine Monroe",
            school: "Princeton High School",
          },
        ],
      },
      {
        teamName: "Aloe Points",
        members: [
          {
            name: "Adarsh Kumar",
            school: "University of Chicago",
          },
          {
            name: "Saloni Mundhra",
            school: "University of Chicago",
          },
        ],
      },
    ]
  },
  {
    track: "High School",
    teams: [
      {
        teamName: "Algology",
        members: [
          {
            name: "Christopher Kwok",
            school: "Sequoia High School",
          },
          {
            name: "Nicholas Kwok",
            school: "Sequoia High School",
          },
        ],
      },
      {
        teamName: "Amazon Drone",
        members: [
          {
            name: "Lucas Tejedor da Silva",
            school: "Centro Tecnológico Federal Celso Suckow da Fonseca",
          },
        ]
      },
    ]
  },
]
